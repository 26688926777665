/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Inter Fallback';src: local("Arial");ascent-override: 90.44%;descent-override: 22.52%;line-gap-override: 0.00%;size-adjust: 107.12%
}.__className_d65c78 {font-family: 'Inter', 'Inter Fallback';font-style: normal
}

/* Scrollbar */
/* Styling the scrollbar */
::-webkit-scrollbar {
  width: 8px; /* Width of vertical scrollbar */
  height: 8px; /* Height of horizontal scrollbar */
}

/* Styling the scrollbar track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the track */
  border-radius: 10px; /* Rounded corners */
}

/* Styling the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: #a6a8a6; /* Color of the thumb */
  border-radius: 10px; /* Rounded corners */
}

/* Hover effect on the thumb */
::-webkit-scrollbar-thumb:hover {
  background: #a6a8a6; /* Darker color on hover */
}

.no-number-input-buttons::-webkit-inner-spin-button,
.no-number-input-buttons::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.no-number-input-buttons {
  -moz-appearance: textfield !important;
  -webkit-appearance: textfield !important;
          appearance: textfield !important;
}

.tiptap {
  :first-child {
    margin-top: 0;
  }
  outline: none;
  padding: 10px 12px;
  line-height: 1.46;
  font-size: 15px;
  -moz-tab-size: 8;
       tab-size: 8;
  cursor: text;
  -webkit-user-select: text;
     -moz-user-select: text;
          user-select: text;
  height: 100%;
  width: 100%;

  /* List styles */
  ul,
  ol {
    margin-left: 24px;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }
}

/* Placeholder styles - Note: Inside .tiptap didn't work when deployed */
.tiptap p.is-editor-empty:first-child::before {
  color: #868c91;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
  font-weight: 500;
}

/* Mention styles */
.mention {
  border-radius: 0.4rem;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
  padding: 0.1rem 0.3rem;
}

.mention.conversation-member-mention {
  background-color: var(--chakra-colors-blue-50);
  color: var(--chakra-colors-blue-500);
}

.mention.task-mention {
  background-color: var(--chakra-colors-purple-50);
  color: var(--chakra-colors-purple-500);
}

.mention.piece-of-information-mention {
  background-color: var(--chakra-colors-green-50);
  color: var(--chakra-colors-green-500);
}


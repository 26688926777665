/* Scrollbar */
/* Styling the scrollbar */
::-webkit-scrollbar {
  width: 8px; /* Width of vertical scrollbar */
  height: 8px; /* Height of horizontal scrollbar */
}

/* Styling the scrollbar track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the track */
  border-radius: 10px; /* Rounded corners */
}

/* Styling the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: #a6a8a6; /* Color of the thumb */
  border-radius: 10px; /* Rounded corners */
}

/* Hover effect on the thumb */
::-webkit-scrollbar-thumb:hover {
  background: #a6a8a6; /* Darker color on hover */
}

.no-number-input-buttons::-webkit-inner-spin-button,
.no-number-input-buttons::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.no-number-input-buttons {
  -moz-appearance: textfield !important;
  appearance: textfield !important;
}

.tiptap {
  :first-child {
    margin-top: 0;
  }
  outline: none;
  padding: 10px 12px;
  line-height: 1.46;
  font-size: 15px;
  tab-size: 8;
  cursor: text;
  user-select: text;
  height: 100%;
  width: 100%;

  /* List styles */
  ul,
  ol {
    margin-left: 24px;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }
}

/* Placeholder styles - Note: Inside .tiptap didn't work when deployed */
.tiptap p.is-editor-empty:first-child::before {
  color: #868c91;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
  font-weight: 500;
}

/* Mention styles */
.mention {
  border-radius: 0.4rem;
  box-decoration-break: clone;
  padding: 0.1rem 0.3rem;
}

.mention.conversation-member-mention {
  background-color: var(--chakra-colors-blue-50);
  color: var(--chakra-colors-blue-500);
}

.mention.task-mention {
  background-color: var(--chakra-colors-purple-50);
  color: var(--chakra-colors-purple-500);
}

.mention.piece-of-information-mention {
  background-color: var(--chakra-colors-green-50);
  color: var(--chakra-colors-green-500);
}
